import { defineMessages } from 'react-intl';

export const {
  autoSyncTitle,
  autoSyncDescription,
  dedicatedSupportTitle,
  dedicatedSupportDescription,
  unlimitedTransfersTitle,
  unlimitedTransfersDescription,
  allPlatformsTitle,
  allPlatformsDescription,
  cloudBackupTitle,
  cloudBackupDescription,
  cancelAnytimeTitle,
  cancelAnytimeDescription,
  rematchTitle,
  rematchDescription,
  songOrderTitle,
  songOrderDescription,
  multiAccountTitle,
  multiAccountDescription
} = defineMessages({
  autoSyncTitle: {
    id: 'web.features.auto_sync.title',
    defaultMessage: 'Auto-Sync'
  },
  autoSyncDescription: {
    id: 'web.features.auto_sync.description',
    defaultMessage: '15-minute auto-synchronization of your playlists and albums.'
  },
  dedicatedSupportTitle: {
    id: 'web.features.dedicated_support.title',
    defaultMessage: 'Dedicated Support'
  },
  dedicatedSupportDescription: {
    id: 'web.features.dedicated_support.description',
    defaultMessage: 'Expert dedicated tech & issues support.'
  },
  unlimitedTransfersTitle: {
    id: 'web.features.unlimited_transfers.title',
    defaultMessage: 'Unlimited Transfers'
  },
  unlimitedTransfersDescription: {
    id: 'web.features.unlimited_transfers.description',
    defaultMessage: 'Transfer unlimited number of songs and playlists and albums.'
  },
  allPlatformsTitle: {
    id: 'web.features.all_platforms.title',
    defaultMessage: 'All platforms'
  },
  allPlatformsDescription: {
    id: 'web.features.all_platforms.description',
    defaultMessage: 'iOS, Android, Windows, MacOS & Linux'
  },
  cloudBackupTitle: {
    id: 'web.features.cloud_backup.title',
    defaultMessage: 'Cloud Backup'
  },
  cloudBackupDescription: {
    id: 'web.features.cloud_backup.description',
    defaultMessage: 'Playlist security with cloud storage.'
  },
  cancelAnytimeTitle: {
    id: 'web.features.cancel_anytime.title',
    defaultMessage: 'Cancel anytime'
  },
  cancelAnytimeDescription: {
    id: 'web.features.cancel_anytime.description',
    defaultMessage: 'Cancel premium and keep Basic features - forever.'
  },
  rematchTitle: {
    id: 'web.features.rematch.title',
    defaultMessage: 'Rematch'
  },
  rematchDescription: {
    id: 'web.features.rematch.description',
    defaultMessage: 'One button fix for missing or incorrect songs.'
  },
  songOrderTitle: {
    id: 'web.features.keep_order.title',
    defaultMessage: 'Song order'
  },
  songOrderDescription: {
    id: 'web.features.keep_order.description',
    defaultMessage: 'Your playlist as you intended'
  },
  multiAccountTitle: {
    id: 'web.features.multi_account.title',
    defaultMessage: 'Multi-account support'
  },
  multiAccountDescription: {
    id: 'web.features.multi_account.description',
    defaultMessage: `One license, multiple accounts.`
  }
});
